<template>
  <app-panel type="panel"
             transition="fade"
             event-name="openProductEditor"
             ref="productEditor"
             class="product-modal" :class="{'product-modal-small': product && !product.imageLarge.path}"
             hide-validate-button
             hide-nav
             @open="open"
             @close="close">

    <div v-if="product" class="product-container">

      <div class="product-full-image d-none d-xl-block" v-if="product.imageLarge.path" :style="{'background-image': 'url('+product.imageLarge.path+')'}"></div>

      <div class="product-data" :class="{'product-data__width': product.imageLarge.path}">
        <div class="product-data__close" :class="{'product-data__close-show': !product.imageLarge.path}">
          <i class="material-icons notranslate" @click="$refs.productEditor.close()">close</i>
        </div>
        <div class="product-data__container scroll">
          <div class="product-image d-xl-none" v-if="product.imageLarge.path" :style="{'background-image': 'url('+product.imageLarge.path+')'}">
            <div class="product-image__close">
              <i class="material-icons notranslate" @click="$refs.productEditor.close()">close</i>
            </div>
          </div>
          <div class="product-title">{{product.name}}</div>
          <div class="product-description">{{product.description}}</div>
          <div class="product-alcohol" v-if="product.alcohol">
            <i class="material-icons notranslate">info</i>{{$t('alcohol_age_prevention_product')}}
          </div>
          <div class="product-attributes" v-if="product.attributes.length !== 0">
            <div class="attribute" v-for="attribute in product.attributes"><i class="material-icons notranslate" v-if="attribute.icon" :style="{'color':attribute.iconColor}">{{attribute.icon}}</i>{{attribute.value}}</div>
          </div>
          <hr>
          <app-product-option event-name="openedProductEditUpdateOption" :option="option" v-bind:key="option.id" v-for="option in product.options"></app-product-option>
          <div class="product-comment" v-if="cart.type !== 'on_site'">
            <label>{{$t('product_comment')}}</label>
            <textarea v-model="comment" class="form-control" :placeholder="$t('product_comment_placeholder')"></textarea>
          </div>
          <div class="product-quantity">
            <ui-icon-button icon="remove" @click="changeQuantity('sub')" data-test-id="product_sub_quantity"></ui-icon-button>
            <strong data-test-id="product_quantity">{{quantity}}</strong>
            <ui-icon-button icon="add" @click="changeQuantity('add')" data-test-id="product_add_quantity"></ui-icon-button>
          </div>
        </div>
        <div class="product-add-button">
          <ui-button v-if="quantity > 0 && product.avaible" :color="isValid ? 'green' : 'default'" size="large" @click="UpdateButton" :loading="isLoading">
            <span>{{ $t('product_cart_update', {quantity: this.quantity}) }}</span>
            <small v-if="priceFormated && price !== 0">{{ priceFormated }}</small>
          </ui-button>
          <ui-button v-if="quantity === 0 && product.avaible" color="red" class="cart-btn-center" size="large" :disabled="!isValid" @click="UpdateButton" :loading="isLoading">
            <span>{{ $t('product_cart_delete') }}</span>
          </ui-button>
          <ui-button color="default" size="large" v-if="!product.avaible" disable-ripple>
            <span style="grid-column: 1 / 4;">{{ $t('product_unavaible') }}</span>
          </ui-button>
        </div>
      </div>
    </div>





  </app-panel>
</template>

<script>
import NavCartButton from '../Merchant/NavCartButton'
import ProductOption from "@/desktopComponents/Products/ProductOption";
import config from "@/config";
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import PanelTemplate from "@/desktopComponents/PanelTemplate";

export default {
  components: {
    'app-nav-cart-button': NavCartButton,
    'app-product-option': ProductOption,
    'app-panel': PanelTemplate
  },
  data(){
    return {
      cartProduct: null,
      product: null,
      quantity: 1,
      price: 0,
      priceFormated: null,
      isLoading: false,
      errors: null,
      selectedOptions: null,
      isValid: false,
      comment: null,
    }
  },
  methods: {
    ...mapActions(['updateCartProduct', 'removeCart']),
    listen(){
      this.$root.$on('openedProductEditUpdateOption', () => {
        this.getTotalPrice();
      });
    },
    open(cartProduct){
      this.cartProduct = cartProduct;
      this.product = cartProduct.product;
      this.quantity = cartProduct.quantity;
      this.comment = cartProduct.comment;
      this.isLoading = false;

      this.getTotalPrice();
    },
    close(){
      if(this.product && this.product.options){
        this.product.options.forEach((option) => {
          option.values.forEach((value) => {
            value.checked = false;
          });
        });
      }

      this.product = null;
      this.price = 0;
      this.quantity = 1;
      this.errors = null;
    },
    changeQuantity(type){
      if(type === 'add'){
        this.quantity++;
      }
      else{
        if(this.quantity > 0){
          this.quantity--;
        }
      }
      this.getTotalPrice();
    },
    getTotalPrice(){
      let optionPrice = 0;
      this.selectedOptions = [];

    if(this.product && this.product.options){
        this.product.options.forEach((option) => {
          option.values.forEach((value) => {
            if(value.checked && !option.quantityEnabled){
              optionPrice += value.price.value;
              this.selectedOptions.push({optionId: option.id, valueId: value.id, quantity: 1});
            }
            if(option.quantityEnabled && value.quantity > 0){
              optionPrice += value.price.value * value.quantity;
              this.selectedOptions.push({optionId: option.id, valueId: value.id, quantity: value.quantity});
            }
          });
        });
      }


      if(this.cart.type === 'on_site'){
        this.price = (this.product.priceOnsite.value + optionPrice) * this.quantity;
      }
      if(this.cart.type === 'pickup'){
        this.price = (this.product.pricePickup.value + optionPrice) * this.quantity;
      }
      if(this.cart.type === 'delivery'){
        this.price = (this.product.priceDelivery.value + optionPrice) * this.quantity;
      }
    },


    validator(errorMessage = true){
      let errors = [];


      this.product.options.forEach((option) => {
        if(option.required){
          let count = 0;
          option.values.forEach((value) => {
            if(value.checked && !option.quantityEnabled){
              count++;
            }
            if(option.quantityEnabled){
              count += value.quantity;
            }
          });
          if(!(option.minSelect <= count && option.maxSelect >= count && count >= 1)){
            errors.push(option.id);
          }
        }
      });


      if(errorMessage && errors.length !== 0){
        animateScrollTo(document.getElementById('option_' + errors[0]), {elementToScroll: document.querySelector('.product-modal .panel-body')});

        document.querySelector('#option_' + errors[0] + ' .name').classList.add('animate__shakeX');
        setTimeout(() => {
          document.querySelector('#option_' + errors[0] + ' .name').classList.remove('animate__shakeX');
        }, 1000);


        this.errors = errors;
      }
      return errors.length === 0;
    },
    UpdateButton(){
      if(!this.validator()){
        return false;
      }

      this.isLoading = true;
      this.updateCartProduct({options: this.selectedOptions, comment: this.comment, productId: this.product.id, cartProductId: this.cartProduct.id, quantity: this.quantity}).then(() => {
        this.isLoading = false;
        this.$refs.productEditor.close()
        this.$root.$emit('openCart')
      });
    }
  },
  watch: {
    price(){
      let formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      });
      this.priceFormated = formatter.format(this.price / 100);
    },
    product:{
      deep: true,
      handler(val){
        if(val !== null){
          this.isValid = this.validator(false);
        }
      }
    }
  },
  computed: {
    ...mapGetters(['orderToken', 'userToken', 'cart']),
  },
  mounted() {
    this.listen();
  }
}
</script>
