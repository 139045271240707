<template>
  <app-page>
    <div class="loading-screen" v-if="isLoading">
      <ui-progress-circular color="black"></ui-progress-circular>
    </div>

    <div v-if="!isLoading">
      <app-nav minified></app-nav>
      <app-auth v-if="!user"></app-auth>
      <app-onboarding v-if="user && user.onboarding === true"></app-onboarding>
      <div v-if="user && user.onboarding === false">
        <app-summary></app-summary>
        <app-product-editor></app-product-editor>
        <app-addresses></app-addresses>
        <app-address-editor></app-address-editor>
        <app-delivery-info></app-delivery-info>
        <app-platform-fee-info></app-platform-fee-info>
        <app-order-time></app-order-time>
      </div>
    </div>

  </app-page>
</template>

<script>
import Auth from "@/desktopComponents/User/Auth";
import '../scss/app_desktop.scss'
import Nav from "@/desktopComponents/Nav";
import {mapActions, mapGetters} from 'vuex';
import Summary from "@/desktopComponents/Merchant/Summary";
import CartComment from "@/desktopComponents/Merchant/CartComment";
import Login from "@/desktopComponents/User/Login";
import Register from "@/desktopComponents/User/Register";
import OnboardingAccount from "@/desktopComponents/User/OnboardingAccount";
import Addresses from "@/desktopComponents/Merchant/Addresses";
import EditProduct from "@/desktopComponents/Products/EditProduct";
import DeliveryInfo from "@/desktopComponents/Merchant/DeliveryInfo";
import PlatformFeeInfo from "@/desktopComponents/Merchant/PlatformFeeInfo";
import AddressEditor from "@/desktopComponents/Merchant/AddressEditor";
import OrderTime from "@/desktopComponents/Merchant/OrderTime";

export default {
  data(){
    return {
      page: 'main'
    }
  },
  components: {
    'app-auth': Auth,
    'app-nav': Nav,
    'app-product-editor': EditProduct,
    'app-summary': Summary,
    'app-comment': CartComment,
    'app-login': Login,
    'app-register': Register,
    'app-onboarding': OnboardingAccount,
    'app-addresses': Addresses,
    'app-address-editor': AddressEditor,
    'app-delivery-info': DeliveryInfo,
    'app-platform-fee-info': PlatformFeeInfo,
    'app-order-time': OrderTime
  },
  methods:{
    ...mapActions(['getMerchant', 'createOrderToken', 'loadOrder', 'getAccessToken', 'getUser', 'getNearestAddress', 'setOrderType', 'backButton', 'setOrderTracking'])
  },
  computed: {
    ...mapGetters(['menu', 'merchant', 'userToken', 'user', 'cart', 'merchantError', 'modal']),
    isLoading(){
      return !(this.menu !== null && this.merchant !== null && this.user !== null && this.cart !== null);
    }
  },
  async mounted(){
    window.scrollTo(0, 0);
    const slug = this.$route.params.merchantSlug ? this.$route.params.merchantSlug : location.host;
    await this.getMerchant({slug: slug});
    this.getUser();
    let source = 'direct';
    if(this.$route.query.source === 'marketplace'){
      source = 'marketplace';
    }
    this.loadOrder({slug: this.merchant.slug, type: this.$route.query.type, source});
  },
  watch: {
    cart(val){
      if(val.OrderProducts.length === 0){
        this.$router.push({name:'merchant', params:{merchantSlug:this.$route.params.merchantSlug}, query: this.$route.query})
      }
    },
    userToken(){
      if(this.$route.query.authed){
        this.getAccessToken();
      }
    },
  }
}
</script>
