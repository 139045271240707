<template>
  <div class="product-option-container" :id="'option_'+option.id">
    <span class="name animate__animated">{{option.name}}</span>

    <small v-if="option.required" :class="{'text-danger':option.error, 'text-success':!option.error}">
      <span v-if="option.minSelect <= 1 && option.minSelect !== option.maxSelect">{{$t('product_required_option_max', {max: option.maxSelect})}}</span>
      <span v-if="option.minSelect > 1 && option.minSelect !== option.maxSelect">{{$t('product_required_option_min', {max: option.maxSelect, min: option.minSelect})}}</span>
      <span v-if="option.minSelect === option.maxSelect">{{$t('product_required_option', {max: option.maxSelect})}}</span>
    </small>

    <small v-if="!option.required && option.maxSelect">{{$t('product_optionnal_option_count', {max: option.maxSelect})}}</small>
    <small v-if="!option.required && !option.maxSelect">{{$t('product_optionnal_option')}}</small>


    <div class="product-option-row-group">

      <div class="product-option-row-unique" v-for="value in option.values" v-bind:key="value.id" v-if="!option.quantityEnabled">
        <ui-checkbox v-model="value.checked" @change="check(value.id)" :disabled="!value.available">{{value.name}}</ui-checkbox>
        <span class="price" v-if="value.price.value !== 0 && value.available">+{{value.price.price}}</span>
        <span v-if="!value.available" class="unavailable">{{$t('unavaible')}}</span>
      </div>

      <div class="product-option-row-quantity" v-for="value in option.values" v-bind:key="value.id" v-if="option.quantityEnabled">

        <div class="quantity-selector" :class="{'quantity-selector-disabled':!value.available}">
          <button @click="updateQuantity(value, -1)" :class="{'quantity-selector-active': value.quantity > 0}" v-if="value.quantity > 0">-</button>
          <span v-if="value.quantity > 0">{{value.quantity}}</span>
          <button @click="updateQuantity(value, 1)" :class="{'quantity-selector-active': value.quantity > 0}">+</button>
        </div>


        <label class="name" :class="{'quantity-selector-disabled':!value.available}">{{value.name}}</label>
        <span class="price" v-if="value.price.value !== 0 && value.available">+{{value.price.price}}</span>
        <span v-if="!value.available" class="unavailable">{{$t('unavaible')}}</span>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['option', 'eventName', 'newProduct'],
  data(){
    return {

    }
  },
  methods: {
    check(id){
      if(this.option.maxSelect === 1){
        // replace
        this.option.values.forEach((value) => {
          if(value.id !== id){
            value.checked = false;
          }
        });
      }
      if(this.option.maxSelect > 1){
        let checked = this.option.values.filter(value => value.checked === true);
        if(checked.length > this.option.maxSelect){
          let option = this.option.values.find(value => value.id === id);
          option.checked = false;
        }
      }

      this.$root.$emit(this.eventName);
      this.validator();
    },

    updateQuantity(value, quantity)
    {
      let total = 0;
      this.option.values.forEach((value) => {
        total += value.quantity;
      });
      if(total >= this.option.maxSelect && this.option.maxSelect !== 0 && quantity > 0){
        return;
      }
      if(!value.available){
        return;
      }

      value.quantity += quantity;
      if(value.quantity <= 0){
        value.quantity = 0;
        value.checked = false;
      }
      else{
        value.checked = true;
      }
      this.$root.$emit(this.eventName);
      this.validator();
    },

    validator(){
      if(this.option.required){
        let count = 0;
        this.option.values.forEach((value) => {
          if(value.checked && !this.option.quantityEnabled){
            count++;
          }
          if(this.option.quantityEnabled){
            count += value.quantity;
          }
        });
        this.option.error = false;

        if(this.option.minSelect <= count && this.option.maxSelect >= count && count >= 1){
          this.option.error = false;
        }
        else{
          this.option.error = true;
        }

      }
    },
    reset(){
      if(this.newProduct){
        this.option.values.forEach((v) => {
          v.quantity = 0;
          v.checked = false;
        })
      }
    }
  },
  computed: {
    ...mapGetters(['cart'])
  },
  mounted() {
    this.validator();
    this.reset();
  }
}
</script>
