<template>
  <div class="card-discount-code">
    <a href="#" v-if="!isOpen" @click.prevent="open">{{ $t('cart_add_promo_code') }}</a>
    <div class="discount-code" v-if="isOpen">
      <input type="text" placeholder="Code promo" v-model="code" style="height: 32px;" :class="{'is-invalid': isInvalid}" class="form-control form-control-sm">
      <ui-button color="primary" @click="save" size="small" :loading="isLoading">Ajouter</ui-button>
      <ui-icon-button icon="close" @click="close" color="clear" size="small"></ui-icon-button>
    </div>
    <small class="text-danger" v-if="error && isOpen">{{ $t(error) }}</small>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data(){
    return {
      isOpen: false,
      code: null,
      isLoading: false,
      isInvalid: false,
      error: false
    }
  },
  methods: {
    ...mapActions(['setOrderDiscountCode']),
    open(){
      this.isOpen = true;
    },
    close(){
      this.isOpen = false;
      this.error = false;
    },
    save(){
      this.isLoading = true;
      this.error = false;

      this.setOrderDiscountCode({code:this.code, force:false})
          .then(() => {
            this.isLoading = false;
            this.isOpen = false;
            this.code = null;

          })
          .catch((error) => {
            this.error = error;
            this.isLoading = false;
          })
    }
  }
}
</script>
